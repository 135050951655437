@import "mastercard_fonts";

:root {
  --mastercard-color: #FF5F00;
  --mastercard-color-2: #F79E1B;
  --mastercard-color-3: #EB001B;
  --mastercard-color-text: #ffff;
}

body, html {
  font-family: "MarkMC-MainSet", sans-serif !important;
}

/* CALENDAR */
.p-calendar {
  width: 100%;
}

.p-datepicker table td > span.p-highlight {
  color: white !important;
  background: var(--mastercard-color-2) !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: var(--mastercard-color-2) !important;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: white !important;
  background: var(--mastercard-color-2) !important;
}

.p-datepicker table td > span:focus, .p-datepicker .p-link:focus, .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus, .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--mastercard-color) !important;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight, .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: white !important;
  background: var(--mastercard-color-2) !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: 0 0 0 0.2rem var(--mastercard-color) !important;
}

/* END CALENDAR */

/* START RADIO BUTTONS */
.field-checkbox > label, .field-radiobutton > label {
  margin-left: 0.4rem !important;
  padding-top: 0.2rem;
}

.p-radiobutton {
  width: 14px !important;
  height: 14px !important;
}

.p-radiobutton .p-radiobutton-box {
  background: white !important;
  border: none !important;
  width: 14px !important;
  height: 14px !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: unset !important;
  background: var(--mastercard-color) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight.p-focus {
  box-shadow: unset !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: unset !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: unset !important;
  border-color: unset !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  display: none !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: unset !important;
}
/* END RADIO BUTTONS */

/* START INPUT TEXT */
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0.1rem 0.2rem var(--mastercard-color) !important;
  border-color: var(--mastercard-color-3);
}

.p-inputtext:enabled:hover {
  border-color: var(--mastercard-color-3);
}
/* END INPUT TEXT */

/* START BUTTON */
.p-button {
  color: #ffffff;
  background: var(--mastercard-color);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 20px;
}

.p-button:enabled:hover {
  background: var(--mastercard-color-2);
  color: #ffffff;
  border-color: var(--mastercard-color);
}

.p-button:focus {
  background: var(--mastercard-color);
  //outline: 0 none;
  //outline-offset: 0;
  //box-shadow: 0 0 0 0.2rem var(--mastercard-color-2);
  box-shadow: unset;
}
/* END BUTTON */

/* START DROPDOWN */
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--mastercard-color-3);
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--mastercard-color);
  border-color: var(--mastercard-color-3);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--mastercard-color);
  color: white;
}
/* END DROPDOWN */

/* CUSTOM MASTERCARD ON WHITE */
.mastercard-input-on-white.p-inputtext, .mastercard-input-on-white.p-dropdown, .mastercard-input-on-white.p-inputtext {
  border-radius: 10px;
  border-color: var(--mastercard-color-2);
  color: black;
  height: 35px !important;
  min-width: 1px !important;
  padding-top: 0.3rem !important;
}

.mastercard-input-on-white.p-inputtext:enabled:focus, .mastercard-input-on-white.p-dropdown:not(.p-disabled).p-focus, .mastercard-input-on-white.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: var(--mastercard-color-2);
}

.mastercard-input-on-white.p-inputtext:enabled:hover, .mastercard-input-on-white.p-dropdown:not(.p-disabled):hover, .mastercard-input-on-white.p-inputtext:enabled:hover {
  border-color: var(--mastercard-color-2);
}
/* CUSTOM MASTERCARD ON WHITE */

/* ALTRO MASTERCARD */
.site-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.content-container {
  padding: inherit !important;
  //padding-left: 20px !important;
  //padding-right: 20px !important;
}

@media (max-width: 480px) {
  .content-container {
    padding: inherit !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.p-component {
  font-family: "MarkMC-MainSet", sans-serif !important;
}

.p-placeholder {
  color: #d7d7d7 !important
}

.stop-propagation {
  pointer-events: none;
  cursor: pointer !important;
}

.bg-mastercard {
  background: #000 !important;
}

.color-mastercard {
  color: var(--mastercard-color) !important;
}

.color-mastercard-2 {
  color: var(--mastercard-color-2) !important;
}

.card-mastercard, p-card {
  max-width: 1200px;
  width: 100%;
}

.mastercard-input {
  background: black;
  border-radius: 10px;
  border-color: var(--mastercard-color-2);
  color: #fff;
  height: 35px !important;
  min-width: 1px !important;
  padding-top: 0.5rem !important;
  margin-top: -0.2rem !important;
}

.mastercard-input-yellow .p-dropdown-label {
  color: #fff;
}

.mastercard-input-yellow.p-inputtext, .mastercard-input-yellow .p-dropdown, .mastercard-input-yellow .p-inputtext {
  @extend .mastercard-input;
  border-color: var(--mastercard-color-2);
}

.mastercard-input-yellow.p-inputtext:enabled:hover, .mastercard-input-yellow .p-dropdown:not(.p-disabled):hover, .mastercard-input-yellow .p-inputtext:enabled:hover {
  border-color: var(--mastercard-color);
}

.mastercard-input-yellow.p-inputtext:enabled:focus, .mastercard-input-yellow .p-dropdown:not(.p-disabled).p-focus, .mastercard-input-yellow .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: var(--mastercard-color);
}

.mastercard-input-red .p-dropdown-label {
  color: #fff;
}

.mastercard-input-red.p-inputtext, .mastercard-input-red .p-dropdown, .mastercard-input-red .p-inputtext {
  @extend .mastercard-input;
  border-color: var(--mastercard-color-2) !important;
}

.mastercard-input-red.p-inputtext:enabled:hover, .mastercard-input-red .p-dropdown:not(.p-disabled):hover, .mastercard-input-red .p-inputtext:enabled:hover {
  border-color: var(--mastercard-color) !important;
}

.mastercard-input-red.p-inputtext:enabled:focus, .mastercard-input-red .p-dropdown:not(.p-disabled).p-focus, .mastercard-input-red .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: var(--mastercard-color) !important;
}

.disabled-input .mastercard-input-yellow.p-inputtext, .disabled-input .mastercard-input-red.p-inputtext {
  pointer-events: none;
  border-color: gray !important;
}

.disabled-input span {
  color: gray !important;
}

.orange-box {
  background: var(--mastercard-color);
  height: 30px;
  width: 100%;
}

.img-placeholder {
  width: 250px;
  height: 250px;
  background: gray;
  margin: 5px;
}

.title-box {
  background: var(--mastercard-color);
  border-radius: 10px;
  border: 2px solid var(--mastercard-color);
  width: 100%;
  color: white;
  position: relative;
}

.text-title-box {
  padding: 0 15px;
  color: white;
  height: 100%;
  margin-top: -2px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.text-comment-box {
  background: black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-subtitle {
  display: flex;
  flex-direction: column;
}

.font-size-title {
  font-weight: 600;
  font-size: 24px;
  //padding-top: 1px!important;
}

.font-size-subtitle {
  padding-top: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  color: white;
  //padding-top: 1px!important;
}

.font-size-sub-header {
  font-weight: 400;
  font-size: 18px;
  //padding-top: 1px!important;
}

.font-size-label {
  font-weight: 400;
  font-size: 18px;
  color: white;
  line-height: 1.2;
}

.font-size-text {
  font-weight: 400;
  font-size: 16px;
  color: white !important;
}

.font-size-field {
  font-weight: 400;
  font-size: 18px;
  color: var(--mastercard-color);
}

.border-col {
  border-right: 1px solid gray;
}

.border-bottom {
  border-bottom: 1px solid gray;
}

.padding-col {
  padding: 5px 10px;
}

.margin-top-field {
  margin-top: 10px;
}

.mastercard-footer {
  background: var(--mastercard-color);
  font-family: MarkMC-MainSet, sans-serif !important;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mastercard-footer-fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.footer-mail {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.divider {
  margin: 0 30px;
  width: 1px;
  background: #fff;
  height: 70px;
}

.title-header {
  color: #fff;
  font-size: 30px;
}

.sub-title-header {
  color: #fff;
  font-size: 17px;
}

.p-card-content {
  padding: 0 !important;
}

.btn-mastercard-on-white, .p-confirm-dialog-reject, .p-confirm-dialog-accept {
  background: white !important;
  border: none !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  padding-top: 0.4rem !important;
}

.btn-mastercard-on-white:hover, .p-confirm-dialog-reject:hover, .p-confirm-dialog-accept:hover {
  background: darkgrey !important;
}

.btn-mastercard-on-white:focus, .p-confirm-dialog-reject:focus, .p-confirm-dialog-accept:focus {
  box-shadow: none !important;
}

.btn-mastercard, .p-confirm-dialog-reject, .p-confirm-dialog-accept {
  background: var(--mastercard-color) !important;
  border: none !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  padding-top: 0.4rem !important;
}

.btn-mastercard:hover, .p-confirm-dialog-reject:hover, .p-confirm-dialog-accept:hover {
  background: var(--mastercard-color-2) !important;
}

.btn-mastercard:focus, .p-confirm-dialog-reject:focus, .p-confirm-dialog-accept:focus {
  box-shadow: none !important;
}

.formgroup-inline {
  flex-wrap: nowrap !important;
}

.mastercard-link {
  color: var(--mastercard-color) !important;
  cursor: pointer;
}

.mastercard-link:hover {
  color: var(--mastercard-color-2) !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--mastercard-color) !important;
  background: var(--mastercard-color) !important;
}

.p-focus {
  box-shadow: none !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--mastercard-color) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--mastercard-color) !important;
}

//Rimuovo il border da mobile
@media only screen and (max-width: 991px) {
  .border-col {
    border-right: none;
  }

  .mastercard-footer {
    flex-direction: column;
  }

  .divider {
    display: none;
  }

  .footer-mail {
    margin-top: 30px;
  }

  .text-comment-box {
    background: black;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

}

li {
  color: white;
}
