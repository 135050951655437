/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.min.css";
@import "primeng/resources/primeng.min.css";

@font-face {
  font-family: 'MarkForMC';
  src: url('assets/fonts/MarkMC-MainSet/MarkForMC.ttf') format('truetype')
}

:root {
  // BUTTON COLORS
  --button-color-hover: #00ffff;
  --button-color-active: #00ffff;
  --primary-color: #461e7e;
  --mastercard-color: #FF5F00;
  --mastercard-color-2: #F79E1B;
  --mastercard-color-3: #EB001B;
  --mastercard-color-text: #ffff;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background: #e4ede8;
}

.w-100 {
  width: 100% !important;
}

.p-card-body-no-padding > .p-card > .p-card-body {
  padding: 0;
}

p-card {
  max-width: 940px;
}

.p-tooltip > .p-tooltip-text {
  background-color: var(--enelx-color) !important;
  color: var(--enelx-color-text) !important;
}

img {
  image-rendering: optimizeQuality;
}

.img-fluid {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.underline-span {
  position: relative;
}

.underline-span:before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -10px;
  border-bottom: 5px solid #345edd !important;
}

.ses-color-text {
  color: var(--ses-question-color) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.color-white {
  color: #fff !important;
}

.p-dropdown-label, .p-dropdown {
  max-height: 35px;
  width: 100%;
}

.p-dropdown-label {
  display: flex !important;
  align-items: center;
  font-style: italic;
}

.no-bottom-padding .p-card-content {
  padding-bottom: 0 !important;
}

.w-940 {
  width: 940px;
}

input {
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 100%;
}

.single-button .p-button {
  color: #ffffff;
  background: #595959;
  border: 1px solid #595959;
  width: 250px;
}

.single-button .p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #808080, 0 1px 2px 0 black;
}

.single-button .p-button:enabled:hover {
  background: #3f3f3f;
  color: #ffffff;
  border-color: #3f3f3f;
}

.p-progress-spinner-circle {
  stroke: #ff5f00 !important;
}

.netcomm-2024-container{
  font-family: MarkForMC, sans-serif!important;
}

.p-card .p-card-content{
  padding-top: 0px!important;
}

.p-card .p-card-subtitle{
  color: black!important;
  font-weight: bold;
}

.p-overlay.p-component{
  max-width: 100%!important;
}

.dropdown-masterclass .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  //overflow-x: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 360px) {
  .dropdown-masterclass .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    font-size: 12px!important;
  }
}

.dropdown-masterclass .p-element.p-dropdown-label {
  position: relative;
  top: -3px;
  display: block !important;
}

.p-tooltip .p-tooltip-text {
  background: white!important;
  color: #495057!important;
}
