@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-It.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

//100
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Hairline.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

//200
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Thin.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

//300
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Lt.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

//400
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Book.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

//500
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Med.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

//600
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Heavy.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

//700
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

//900
@font-face {
  font-family: "MarkMC-MainSet";
  src: local("MarkMC-MainSet"), url(/assets/fonts/MarkMC-MainSet/MarkForMC-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

